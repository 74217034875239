var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "[ grid app-wrapper ]", attrs: { id: "app" } },
    [
      _vm.layout
        ? _c(
            "div",
            { staticClass: "app-content" },
            [_c(_vm.layout, { tag: "component" }, [_c("router-view")], 1)],
            1
          )
        : _c(
            "div",
            {
              staticClass: "[ app-content self-center ]",
              staticStyle: { "justify-self": "center" },
            },
            [_vm._v("Loading...")]
          ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }