import { getMultiParamModule, MultiParamAction } from '@/modules/core';
import { Module, Mutation, VuexModule } from 'vuex-module-decorators';
import { appService } from '@/services/app';
import store from './index';

const MODULE_NAME = 'App';

@Module({ namespaced: true, name: MODULE_NAME, dynamic: true, store })
class Store extends VuexModule {

  // ------------------------------------------------------------------------
  // Getters
  // ------------------------------------------------------------------------

  // ------------------------------------------------------------------------
  // Actions
  // ------------------------------------------------------------------------

  @MultiParamAction()
  public async isAuthenticated() {
    const result = await appService.isAuthenticated();

    return result;
  }

  // ------------------------------------------------------------------------
  // Mutations
  // ------------------------------------------------------------------------


}

const app = getMultiParamModule<Store>(Store, MODULE_NAME, store);

export {
    app as AppStore,
};

