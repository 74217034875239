import { all as merge } from 'deepmerge';
import envDev from './env.dev';
import envProd from './env.prod';
import { ProcessEnv } from './process-env';

// ----------------------------------------------------------------------------
// Module Types
// ----------------------------------------------------------------------------
type Env = typeof env & typeof envDev & typeof envProd;

// ----------------------------------------------------------------------------
// Module Vars
// ----------------------------------------------------------------------------
const {
  NODE_ENV,
  VUE_APP_GOOGLE_MAPS_API_KEY,
  VUE_APP_STRIPE_KEY,
  VUE_APP_ALGOLIA_APP_ID,
  VUE_APP_ALGOLIA_API_KEY,
  VUE_APP_API_TIMEOUT_MS,
  VUE_APP_BASE_URL,
} = process.env as ProcessEnv;
const IS_DEV = NODE_ENV !== 'production';

const API_TIMEOUT_MS = 15000;

const env = {
  // Add non-environment specific defaults here
  theme: 'default',
  enableAnimations: true,
  brand: {
    LOGO: '/assets/img/brand-logo.png',
  },
  api: {
    BASE_URL: VUE_APP_BASE_URL ?? 'https://t.tentrr.com',
    GET_USER_PATH: '',

    ENV_NAME: '',
  },
  GOOGLE_MAPS_API_KEY:
    VUE_APP_GOOGLE_MAPS_API_KEY ?? 'AIzaSyCGHIfX_84yhsKodv139fVN-UOzw_ECPzU',
  STRIPE_KEY: VUE_APP_STRIPE_KEY ?? 'pk_test_7IeAWvWF8yD2qrED8Yd8zf3h',
  settings: {
    API_TIMEOUT_MS: parseInt(VUE_APP_API_TIMEOUT_MS, 10) ?? API_TIMEOUT_MS,
  },
  ALGOLIA: {
    APP_ID: VUE_APP_ALGOLIA_APP_ID ?? 'P4DKP1PG2K',
    API_KEY: VUE_APP_ALGOLIA_API_KEY ?? 'e36e2fac0b5260d445cae8a271f78fa7',
  },
};

const mergedEnv = merge([env, IS_DEV ? envDev : envProd]) as Env;

// ----------------------------------------------------------------------------
// Module Exports
// ----------------------------------------------------------------------------
export { mergedEnv as default, IS_DEV };
