import router from '@/config/router';
import App from '@/pages/app/app.vue';
import store from '@/store';
import Vue from 'vue';
import Buefy from 'buefy';
import i18n from '@/config/i18n';
import InstantSearch from 'vue-instantsearch';
import Meta from 'vue-meta';
import VueObserveVisibility from 'vue-observe-visibility';
import 'buefy/dist/buefy.css';
import 'keen-slider/keen-slider.min.css';
import '@/theme/_all.scss';

Vue.use(Buefy, {
  defaultIconComponent: 'font-awesome-icon',
  defaultIconPack: 'fas',
});
Vue.use(InstantSearch);
Vue.use(Meta);
Vue.use(VueObserveVisibility);

Vue.config.productionTip = false;

new Vue({
  i18n,
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');
