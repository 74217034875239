import { Expose } from 'class-transformer';

export class UserProfile {
  @Expose({ name: 'first_name' })
  public firstName = '';

  @Expose({ name: 'last_name' })
  public lastName = '';

  @Expose({ name: 'phone_daytime' })
  public userPhone = '';

  @Expose({ name: 'email' })
  public userEmail = '';
}
