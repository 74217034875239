import env from '@/config/env';
import { AxiosRequestConfig, AxiosResponse } from 'axios';
import { BaseService } from './_base';
import { UserProfile } from '@/models/UserDetails/UserProfile';
import { plainToClass } from 'class-transformer';
import Vue from 'vue';
import { ConfigService } from './config';
import { ContactInfoFormEventDTO } from '@/modules/booking';

// ----------------------------------------------------------------------------
// Module Vars
// ----------------------------------------------------------------------------
const { GET_USER_PATH, BASE_URL, ENV_NAME } = env.api;

// ----------------------------------------------------------------------------
// Module Types
// ----------------------------------------------------------------------------

type ServiceResult<T> = {
  success: boolean;
  // tslint:disable-next-line: no-any
  data?: T;
};

enum StatusCode {
  OK = 200,
  NOT_FOUND = 404,
  SERVER_ERROR = 500,
}
interface APIOptions {
  headers: {
    Authorization: string;
  };
}

class AppService extends BaseService {
  // --------------------------------------------------------------------------
  // Fields
  // --------------------------------------------------------------------------
  private readonly idToken: string;
  private readonly accessToken: string;
  private readonly userDurableKey: string;
  private readonly apiOptions: APIOptions;

  // --------------------------------------------------------------------------
  // Constructor
  // --------------------------------------------------------------------------
  constructor() {
    super({
      baseURL: `${BASE_URL}/profile/${ENV_NAME}`,
    });

    this.idToken = this.getCookieValue('tentrr_id_token');
    this.accessToken = this.getCookieValue('tentrr_access_token');
    this.userDurableKey = this.getCookieValue('tentrr_dk');
    this.apiOptions = {
      headers: {
        Authorization: `Bearer ${this.accessToken}`,
      },
    };
  }

  public async getUserDetails(): Promise<ServiceResult<UserProfile>> {
    const result: ServiceResult<UserProfile> = { success: false };

    if (!this.userDurableKey) {
      return result;
    }

    const apiKey = await this.getApiKey();

    try {
      const response = await this.api.get(`/${this.userDurableKey}`, {
        headers: { 'x-api-key': apiKey },
      });
      const { status, data } = response;
      result.success = status === StatusCode.OK;
      if (result.success) {
        result.data = plainToClass(UserProfile, data);
      }

      return result;
    } catch (error) {
      return result;
    }
  }

  // --------------------------------------------------------------------------
  // Methods
  // --------------------------------------------------------------------------
  private getCookieValue(cookieName: string): string {
    return Vue.$cookies.isKey(cookieName) && !!Vue.$cookies.get(cookieName)
      ? String(Vue.$cookies.get(cookieName))
      : '';
  }

  public async isAuthenticated() {
    // Check if cookies set during login are still available
    // (quick/lightweight check if user is authenticated)
    if (
      this.accessToken !== '' &&
      this.userDurableKey !== '' &&
      this.idToken !== ''
    ) {
      return true;
    }

    return false;
  }

  public async getUserInfo() {
    let result;
    try {
      const { data } = await this.api.post(
        GET_USER_PATH,
        undefined,
        this.apiOptions
      );
      result = { success: true, data };
    } catch (error) {
      result = { success: false, error };
    }

    return result;
  }

  private async getApiKey(): Promise<string> {
    const apiKey = await ConfigService.getTentrrApiKey();

    return apiKey;
  }

  public ageValidated(): boolean {
    return Boolean(this.getCookieValue('AB-age-verified'));
  }

  public setAgeValidated(): void {
    Vue.$cookies.set('AB-age-verified', true);
  }

  public getBirthDate(): string {
    return this.getCookieValue('AB-birth-date');
  }

  public getCachedContact(): ContactInfoFormEventDTO {
    let firstName = '';
    let lastName = '';
    if (Vue.$cookies.get('fullname-cookie')) {
      firstName = (Vue.$cookies.get('fullname-cookie') as string).split(' ')[0];
      lastName = (Vue.$cookies.get('fullname-cookie') as string).split(' ')[1];
    }

    return {
      lastName,
      firstName,
      email: Vue.$cookies.get('email-cookie'),
      phone: Vue.$cookies.get('phone-cookie')
    };
  }

  public setCachedContact(value: ContactInfoFormEventDTO | null): void {
    if (value) {
      let name = '';
      if (value.firstName){
        name = `${value.firstName} ${value.lastName}`;
      }

      Vue.$cookies.set('fullname-cookie', name, '1d');
      Vue.$cookies.set('email-cookie', value.email ? value.email : '', '1d');
      Vue.$cookies.set('phone-cookie', value.phone ? value.phone :'', '1d');
    } else {
      Vue.$cookies.remove('fullname-cookie');
      Vue.$cookies.remove('email-cookie');
      Vue.$cookies.remove('phone-cookie');
    }
  }

  public getCamperCount(): number {
    return this.getCookieValue('camper-count') ? Number(this.getCookieValue('camper-count')) : 1;
  }

  public setCamperCount(value: number): void {
    if (value && value > 0) {
      Vue.$cookies.set('camper-count', value, '1d');
    } else {
      Vue.$cookies.remove('camper-count');
    }
  }

  public getDates() {
    return {
      start: this.getCookieValue('start-date'),
      end: this.getCookieValue('end-date')
    };
  }

  public setEndDate(value: string): void {
    if (value) {
      Vue.$cookies.set('end-date', value, '1d');
    } else {
      Vue.$cookies.remove('end-date');
    }
  }

  public setStartDate(value: string): void {
    if (value) {
      Vue.$cookies.set('start-date', value, '1d');
    } else {
      Vue.$cookies.remove('start-date');
    }
  }

  // --------------------------------------------------------------------------
  // Event Handlers
  // --------------------------------------------------------------------------
  protected onRequest(config: AxiosRequestConfig) {
    // TODO:
  }

  protected onRequestError(error: unknown) {
    // TODO:
  }

  protected onResponse(response: AxiosResponse) {
    // TODO:
  }

  protected onResponseError(error: unknown) {
    // TODO:
  }
}

const appService = new AppService();

export { appService };
